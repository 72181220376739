.expandable-container {
    overflow: hidden;
}

.expandable {
    margin-top: calc(-150%);
    margin-bottom: 1rem;
    transition: all 0.35s;
}

.expandable.expanded {
    margin-top: 0;
    padding: 1rem;
    border-left: 2px solid #334155;
    border-right: 2px solid #334155;
    border-bottom: 2px solid #334155;
    border-radius: 0.375rem;
}